import { Fragment, useEffect } from "react";

import Head from "next/head";

export const Custom404 = () => {

  useEffect(() => {
    window.location.href = '/';
  }, []);

  return (
    <Fragment>
      <Head>
        <title>404</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Head>
    </Fragment>
  );

};

export default Custom404;
